<template>
  <div class="container-table">
    <table class="mn-table mn-table-bordered">
      <thead v-if="enabledHeader">
        <tr>
          <template v-for="(header, index) in headers">
            <th :key="index" :style="{ width: header.width }">
              {{ header.name }}
            </th>
          </template>
        </tr>
      </thead>
      <tbody class="mn-table-tbody">
        <tr v-for="(item, index) in items" :key="index">
          <template v-for="header in headers">
            <td
              v-if="header.pushRoute"
              class="px-2 py-1"
              :key="header.name"
              :class="header.class"
              @click.exact="$emit('push-route', item.slug)"
            >
              <slot :name="header.field" :item="item">
                {{ item[header.field] }}
              </slot>
            </td>

            <td
              v-else
              class="px-2 py-1"
              :key="header.name"
              :class="header.class"
            >
              <slot :name="header.field" :item="item">
                {{ item[header.field] }}
              </slot>
            </td>
          </template>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    },
    headers: {
      type: Array,
      default: () => []
    },
    enabledHeader: {
      type: Boolean,
      default: false
    }
  }
};
</script>
