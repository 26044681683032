<template>
  <b-modal
    v-model="show"
    id="modal-artifacts"
    size="lg"
    title="Artifacts"
    header-bg-variant="secondary"
    header-text-variant="white"
    hide-footer
    no-stacking
    scrollable
    @hidden="resetModal"
  >
    <b-container class="h-100">
      <b-overlay :show="loading">
        <mn-table :items="items" :headers="headers" enabledHeader>
          <template v-slot:actived="{ item }">
            <b-form-checkbox
              v-model="item.actived"
              switch
              @change="onActived($event, item.slug)"
            />
          </template>
          <template v-slot:actions="{ item }">
            <b-button
              v-if="item.public_install_page_url"
              v-b-tooltip.hover
              title="Download App"
              variant="link"
              size="sm"
              target="_blank"
              :href="item.public_install_page_url"
            >
              <font-awesome-icon :icon="['far', 'eye']" size="lg" />
            </b-button>
          </template>
        </mn-table>

        <infinite-loading @infinite="infiniteHandler">
          <div slot="spinner"></div>
          <div slot="no-more"></div>
          <div slot="no-results">No results</div>
        </infinite-loading>
      </b-overlay>
    </b-container>
  </b-modal>
</template>

<script>
import MnTable from "@/components/common/table/MnTable";

export default {
  props: {
    app_slug: {
      type: String,
      require: true
    },
    build_slug: {
      type: String,
      require: true
    }
  },
  components: {
    MnTable
  },
  data() {
    return {
      show: null,
      loading: null,
      items: [],
      headers: [
        {
          name: "Title",
          field: "title"
        },
        {
          name: "Version",
          field: "version"
        },
        {
          name: "Visible",
          field: "actived"
        },
        {
          name: "Actions",
          field: "actions"
        }
      ],
      pagination: {
        current_page: 1,
        per_page: 0,
        total_rows: 0
      },
      page: 1
    };
  },
  computed: {
    totalRows() {
      return this.pagination.total_rows;
    }
  },
  created() {
    this.loading = true;
    let checkGauthLoad = setInterval(() => {
      this.isInit = this.$gAuth.isInit;
      this.isSignIn = this.$gAuth.isAuthorized;

      if (this.isInit) {
        clearInterval(checkGauthLoad);
      }
    }, 1000);
  },
  watch: {
    isSignIn: "supervisor"
  },
  mounted() {
    this.show = true;
  },
  methods: {
    supervisor(isSignIn) {
      if (!isSignIn) {
        this.$store.dispatch("auth/logout");
        this.$router.push({ name: "auth.login" });
        this.loading = false;
      }
    },
    async infiniteHandler($state) {
      let options = null;

      if (!this.pagination.artifact_slug_next && this.page > 1) {
        $state.complete();
        return null;
      }

      if (this.pagination.artifact_slug_next && this.page > 1) {
        options = {
          artifact_slug_next: this.pagination.artifact_slug_next
        };
      }

      const { data, meta } = await this.getAll(options);

      if (data.length) {
        this.items.push(...data);
        this.pagination = meta;
        $state.loaded();
        this.page += 1;
      } else {
        $state.complete();
      }
    },
    async getAll(options = null) {
      try {
        this.loading = true;
        return await this.$store.dispatch("app/artifacts", {
          build_slug: this.build_slug,
          app_slug: this.app_slug,
          options
        });
      } catch (error) {
        console.error(error);
        if (error.response.status === 403) {
          this.$store.dispatch("auth/logout");
          this.$router.push({ name: "auth.login" });
        }
      } finally {
        this.loading = false;
      }
    },
    onActived(event, artifact_slug) {
      this.updateArtifact(artifact_slug, { actived: event });
    },
    async updateArtifact(artifact_slug, data) {
      try {
        this.loading = true;
        const { message } = await this.$store.dispatch("app/updateArtifact", {
          artifact_slug,
          data
        });

        this.$bvToast.toast(message, {
          title: "Success",
          toaster: "b-toaster-bottom-right",
          solid: true,
          variant: "success",
          appendToast: true
        });
      } catch (error) {
        console.error(error);
        this.$bvToast.toast(error.response.data.message.message, {
          title: "Error",
          toaster: "b-toaster-bottom-right",
          solid: true,
          variant: "danger",
          appendToast: true
        });

        if (error.response.status === 403) {
          this.$store.dispatch("auth/logout");
          this.$router.push({ name: "auth.login" });
        }
      } finally {
        this.loading = false;
      }
    },
    changePaginate(page) {
      if (page > this.pagination.current_page) {
        this.getAll({
          artifact_slug_next: this.pagination.artifact_slug_next,
          history: this.pagination.history.join(",")
        });
      } else {
        this.getAll({
          artifact_slug_next: this.pagination.artifact_slug_back,
          history: this.pagination.history.join(",")
        });
      }
    },
    resetModal() {
      this.show = false;
      this.$router.go(-1);
    }
  }
};
</script>
